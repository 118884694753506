import { DragHandle as BaseDragHandle } from "@tiptap-pro/extension-drag-handle-react";
import { useEffect, useRef } from "react";
import { useEditorContext } from "swash/editor";
import { Drag } from "swash/icons";

const NonDraggableNodes = ["title", "chapo"];

export function DragHandle() {
  const { editor } = useEditorContext();

  const ref = useRef<HTMLElement>();

  useEffect(() => {
    if (!ref.current) return;
    ref.current.style.display = "none";
  }, []);

  if (!editor) return null;
  return (
    <BaseDragHandle
      editor={editor}
      onNodeChange={({ node }) => {
        if (!ref.current) return;

        const draggable =
          node != null && !NonDraggableNodes.includes(node.type.name);

        // Achieved with a ref to avoid re-renders
        ref.current.style.display = draggable ? "block" : "none";
      }}
    >
      <Drag ref={ref} className="my-2 cursor-grab text-dusk-300" />
    </BaseDragHandle>
  );
}
