import { Mark, getTextBetween, mergeAttributes } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    anchor: {
      /** Set a anchor mark */
      setAnchor: () => ReturnType;
      /** Toggle a anchor mark */
      toggleAnchor: () => ReturnType;
      /** Unset a anchor mark */
      unsetAnchor: () => ReturnType;
    };
  }
}

type AnchorOptions = {
  prefix: string;
};

export const Anchor = Mark.create<AnchorOptions>({
  name: "anchor",

  addOptions() {
    return {
      prefix: "huit-anchor-",
    };
  },

  addStorage() {
    return {
      getPrefix: () => this.options.prefix,
    };
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("anchorId"),
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        class: "anchor",
        id: this.options.prefix + HTMLAttributes.id,
        anchorId: HTMLAttributes.id,
      }),
      0,
    ];
  },

  addCommands() {
    return {
      setAnchor:
        () =>
        ({ commands }) => {
          const content = getTextBetween(
            this.editor.state.doc,
            this.editor.state.selection,
          );
          return commands.setMark(this.name, {
            id: generateAnchorId(content),
          });
        },
      toggleAnchor:
        () =>
        ({ commands }) => {
          const content = getTextBetween(
            this.editor.state.doc,
            this.editor.state.selection,
          );
          return commands.toggleMark(this.name, {
            id: generateAnchorId(content),
          });
        },
      unsetAnchor:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },
});

const generateAnchorId = (text: string) => {
  return slugify(text.slice(0, 30));
};

/**
 * @example slugify("Hello world !") => "hello-world"
 * @description https://gist.github.com/hagemann/382adfc57adbd5af078dc93feef01fe1
 */
const slugify = (value: string): string => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return value
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, "-and-") // Replace & with 'and'
    .replace(/[^\w-]+/g, "") // Remove all non-word characters
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};
