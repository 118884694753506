import * as Ariakit from "@ariakit/react";
import clsx from "clsx";
import * as React from "react";
import {
  Children,
  ReactElement,
  ReactNode,
  forwardRef,
  isValidElement,
} from "react";

import { VariantProps, cn, cva } from "./utils/classNames";
import { EnumSelect, useEnumSelectState } from "./v2/EnumSelect";

type VariantGetters =
  | typeof getTabVariants
  | typeof getTabListVariants
  | typeof getTabPanelVariants;

type SingleVariantProp<
  Fn extends (...args: any) => any,
  K extends string,
> = NonNullable<
  Fn extends any // HACK: A way to map Union types
    ? VariantProps<Fn> extends { [key in K]?: any }
      ? VariantProps<Fn>[K]
      : never
    : never
>;

export type TabVariant = SingleVariantProp<VariantGetters, "variant">;
export type TabSize = SingleVariantProp<VariantGetters, "size">;
export type TabTheme = SingleVariantProp<VariantGetters, "theme">;

interface TabStateProps extends Ariakit.TabStoreProps {
  /**
   * The variant of the tab system.
   * @default "default"
   */
  variant?: TabVariant;

  /**
   * The size of the tab system.
   * @default "md"
   * */
  size?: TabSize;

  /**
   * The theme of the tab system.
   * @default "light"
   */
  theme?: TabTheme;
}

export interface TabState {
  variant: TabVariant;
  size: TabSize;
  theme: TabTheme;
  store: Ariakit.TabStore;
}

export const useTabState = ({
  variant = "default",
  size = "md",
  theme = "light",
  ...props
}: TabStateProps = {}): TabState => {
  const store = Ariakit.useTabStore(props);
  return React.useMemo(
    () => ({
      store,
      variant,
      size,
      theme,
    }),
    [store, variant, size, theme],
  );
};

export interface TabListProps extends Ariakit.TabListProps {
  state: TabState;
}

const getTabListVariants = cva(/* tw */ `flex select-none`, {
  variants: {
    variant: {
      default: "",
      bar: /* tw */ `rounded justify-around`,
    },
    size: {
      lg: "",
      md: "",
      sm: "",
    },
    theme: {
      light: "",
      dark: "",
    },
  },
  compoundVariants: [
    {
      variant: "bar",
      theme: "light",
      className: /* tw */ `bg-grey-bg-light`,
    },
    {
      variant: "bar",
      theme: "dark",
      className: /* tw */ `bg-dusk-700`,
    },
    {
      variant: "bar",
      size: ["lg", "md"],
      className: /* tw */ `p-1 gap-1`,
    },
    {
      variant: "bar",
      size: "sm",
      className: /* tw */ `p-0.5 gap-0.5`,
    },
  ],
  defaultVariants: {
    variant: "default",
    theme: "light",
    size: "md",
  },
});

export const TabList = forwardRef<HTMLDivElement, TabListProps>(
  ({ state: { store, ...variants }, className, ...props }, ref) => {
    return (
      <Ariakit.TabList
        ref={ref}
        store={store}
        className={cn(getTabListVariants(variants), className)}
        {...props}
      />
    );
  },
);
TabList.displayName = "TabList";

export interface TabSelectListProps extends TabListProps {
  state: TabState;
  children: ReactNode;
}

export const TabSelectList = forwardRef<HTMLDivElement, TabSelectListProps>(
  ({ state, children, className, ...props }, ref) => {
    const tabs = Children.toArray(children).filter(
      (child) => isValidElement(child) && child.type === Tab,
    ) as ReactElement<TabProps>[];

    const items = tabs.map((tab) => {
      const { id: value, label: optionLabel, children } = tab.props;
      return { value, label: children as string, optionLabel };
    });

    const { setSelectedId } = state.store;
    const selectedId = Ariakit.useStoreState(state.store, "selectedId");

    const select = useEnumSelectState({
      value: items.find(({ value }) => value === selectedId) ?? null,
      onChange: (item) => setSelectedId(item?.value),
      items,
      labelSelector: ({ label }) => label,
      labelElementSelector: ({ optionLabel, label }) => optionLabel ?? label,
      required: true,
    });

    return (
      <Ariakit.TabList
        ref={ref}
        store={state.store}
        className={clsx(className, "flex select-none self-end")}
        {...props}
      >
        <EnumSelect state={select} />
      </Ariakit.TabList>
    );
  },
);
TabSelectList.displayName = "TabSelectList";

export interface TabProps extends Ariakit.TabProps {
  state: TabState;
  label?: string;
}

const getTabVariants = cva(
  /* tw */ `font-accent font-bold aria-selected:cursor-default flex gap-2 items-center justify-center`,
  {
    variants: {
      variant: {
        default: /* tw */ `
          relative rounded-tl rounded-tr border-l border-t border-r border-transparent bg-transparent py-1 px-4 text-grey-on transition-colors
          hover:text-grey-on-hover
          disabled:opacity-disabled
          after:absolute after:-bottom-px after:left-0 after:right-0 after:block after:h-px after:transition
        `,
        bar: /* tw */ `rounded transition flex-1`,
      },
      size: {
        lg: /* tw */ `text-md px-2 py-1`,
        md: /* tw */ `text-sm px-2 py-1`,
        sm: /* tw */ `text-xs py-0.5 px-2`,
      },
      theme: {
        light: "",
        dark: "",
      },
    },
    compoundVariants: [
      {
        variant: "default",
        theme: "light",
        className: /* tw */ `
        aria-selected:border-grey-border-light aria-selected:bg-white aria-selected:after:bg-white
        aria-selected:text-dusk-on aria-selected:hover:text-dusk-on
      `,
      },
      {
        variant: "default",
        theme: "dark",
        className: /* tw */ `
        aria-selected:border-grey-border-strong aria-selected:bg-dusk-on aria-selected:after:bg-dusk-on
        aria-selected:text-white aria-selected:hover:text-white
      `,
      },
      {
        variant: "bar",
        theme: "light",
        className: /* tw */ `
        text-grey-on-light
        hover:text-grey-on-hover
        aria-selected:text-dusk-on aria-selected:hover:text-dusk-on aria-selected:shadow-sm aria-selected:bg-white
      `,
      },
      {
        variant: "bar",
        theme: "dark",
        className: /* tw */ `
        text-dusk-300
        hover:text-dusk-200
        aria-selected:text-white aria-selected:hover:text-white aria-selected:shadow-sm aria-selected:bg-dusk-900
      `,
      },
    ],
    defaultVariants: {
      variant: "default",
      theme: "light",
      size: "md",
    },
  },
);

export const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ state: { store, ...variants }, label, className, ...props }, ref) => {
    return (
      <Ariakit.Tab
        ref={ref}
        store={store}
        className={cn(getTabVariants(variants), className)}
        aria-label={label}
        {...props}
      />
    );
  },
);
Tab.displayName = "Tab";

export interface TabPanelProps extends Ariakit.TabPanelProps {
  state: TabState;

  /**
   * Make the panel nude, no style are applied.
   * @default false
   */
  nude?: boolean;

  /**
   * Lazy load the panel content.
   * @default false
   */
  lazy?: boolean;
}

const getTabPanelVariants = cva("", {
  variants: {
    variant: {
      default: "",
      bar: "",
    },
    theme: {
      light: "",
      dark: "",
    },
  },
  compoundVariants: [
    {
      variant: "default",
      className: /* tw */ `rounded rounded-tl-none border bg-white border-grey-border-light`,
    },
    {
      variant: "default",
      theme: "dark",
      className: /* tw */ `bg-dusk-on border-grey-border-strong text-white`,
    },
    {
      variant: "default",
      theme: "light",
      className: /* tw */ `bg-white border-grey-border-light`,
    },
  ],
  defaultVariants: {
    variant: "default",
    theme: "light",
  },
});

export const TabPanel = forwardRef<HTMLDivElement, TabPanelProps>(
  (
    {
      state: { store, ...variants },
      className,
      nude = false,
      lazy = true,
      ...props
    },
    ref,
  ) => {
    const selectedId = Ariakit.useStoreState(store, "selectedId");
    const selected = selectedId === props.tabId;
    return (
      <Ariakit.TabPanel
        ref={ref}
        store={store}
        focusable={false}
        className={cn(!nude && getTabPanelVariants(variants), className)}
        {...props}
      >
        {!lazy || selected ? props.children : null}
      </Ariakit.TabPanel>
    );
  },
);
TabPanel.displayName = "TabPanel";
