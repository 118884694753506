import { useEffect } from "react";
import { Editor, EditorProps, useEditorContext } from "swash/editor";

import { useEditorOptionsSelector } from "@/containers/editor/EditorOptions";

export const RichEditor = (props: RichEditorProps) => {
  const { editor } = useEditorContext();
  const { italicsBetweenQuotes, typographicalCorrection } =
    useEditorOptionsSelector((state) => ({
      typographicalCorrection: state.plugins.some(
        (plugin) => plugin.name === "typographicRules",
      ),
      italicsBetweenQuotes: state.plugins.some(
        (plugin) => plugin.name === "italicsBetweenQuotes",
      ),
    }));

  useEffect(() => {
    if (!editor) return;
    editor.commands.setActiveTypographicalCorrection(typographicalCorrection);
  }, [editor, typographicalCorrection]);

  useEffect(() => {
    if (!editor) return;
    editor.commands.setActiveItalicsBetweenQuotes(italicsBetweenQuotes);
  }, [editor, italicsBetweenQuotes]);

  return <Editor {...props} />;
};

interface RichEditorProps extends EditorProps {}
