import { parsePhoneNumber } from "awesome-phonenumber";
import { isPast } from "date-fns";
import { validate as validateEmail } from "email-validator";
import type { FieldValidator } from "final-form";
import { checkIsValidFullTimeValue } from "swash/controls/TimePickerInput";

import { ERRORS } from "@/config/messages";

export { mustBeURL } from "./mustBeUrl";

const format = (str: string, ...args: unknown[]) => {
  return str.replace(/{(\d+)}/g, (match, number) => {
    return args[number] !== undefined ? String(args[number]) : match;
  });
};

export const mustBeFilled: FieldValidator<any> = (value) => {
  const errorMessage = ERRORS.forms.validators.mustBeFilled;
  if (Array.isArray(value) && value.length === 0) {
    return errorMessage;
  }
  if (typeof value === "string") {
    value = value.trim();
  }
  if (value === "" || value === null || value === undefined) {
    return errorMessage;
  }
  return undefined;
};

export const mustBeEmail: FieldValidator<string> = (value) => {
  if (value && !validateEmail(value)) {
    return ERRORS.forms.validators.mustBeEmail;
  }
  return undefined;
};

export const mustBePhoneNumber =
  (regionCode?: string): FieldValidator<string> =>
  (value) => {
    if (!value) return;
    const phoneParsed = parsePhoneNumber(value, { regionCode });
    if (!phoneParsed.valid) {
      return ERRORS.forms.validators.mustBePhoneNumber;
    }
    return undefined;
  };

export const mustHaveMinLength =
  (min: number): FieldValidator<any[]> =>
  (value) => {
    if (value && value.length < min) {
      return format(ERRORS.forms.validators.mustHaveMinLength, min);
    }
    return undefined;
  };

export const mustHaveMaxLength =
  (max: number): FieldValidator<any[]> =>
  (value) => {
    if (value && value.length > max) {
      return format(ERRORS.forms.validators.mustHaveMaxLength, max);
    }
    return undefined;
  };

export const mustBeMoreThan =
  (min: number): FieldValidator<number> =>
  (value) => {
    if (value && value < min) {
      return format(ERRORS.forms.validators.mustBeMoreThan, min);
    }
    return undefined;
  };

export const mustBeLessThan =
  (max: number): FieldValidator<number> =>
  (value) => {
    if (value && value > max) {
      return format(ERRORS.forms.validators.mustBeLessThan, max);
    }
    return undefined;
  };

export const mustBeFullTime: FieldValidator<string> = (value) => {
  if (value && !checkIsValidFullTimeValue(value)) {
    return ERRORS.forms.validators.mustBeFullTime;
  }
  return undefined;
};

export const mustBeInFuture: FieldValidator<string> = (value) => {
  if (value && isPast(value)) {
    return ERRORS.forms.validators.mustBeInFuture;
  }
  return undefined;
};

export const composeValidators =
  <T>(...validators: (FieldValidator<T> | undefined | null | false)[]) =>
  (...args: Parameters<FieldValidator<T>>) =>
    validators
      .filter((fn): fn is FieldValidator<T> => fn != null)
      .reduce((error, validator) => error || validator(...args), undefined);
