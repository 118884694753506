import { Bold } from "@tiptap/extension-bold";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Heading } from "@tiptap/extension-heading";
import { Italic } from "@tiptap/extension-italic";
import { Underline } from "@tiptap/extension-underline";

import { BlockTemplate } from "../extensions/block-template";
import { Id } from "../extensions/id";
import { InvisibleCharactersExtension } from "../extensions/invisible-characters";
import { RemoveStyleExtension } from "../extensions/remove-style";
import { SpecialCharactersExtension } from "../extensions/special-characters";
import { ToggleCaseExtension } from "../extensions/toggle-case";
import { TypographicRulesExtension } from "../extensions/typographic-rules";
import { Link } from "../marks/link";
import { Strike } from "../marks/strike";
import { Subscript } from "../marks/subscript";
import { Superscript } from "../marks/superscript";
import { Blockquote } from "../nodes/blockquote";
import { BulletList } from "../nodes/bullet-list";
import { EmojiNode } from "../nodes/emoji";
import { ListItem } from "../nodes/list-item";
import { OrderedList } from "../nodes/ordered-list";
import { Core, KitOptions, createKit } from "./base";

export const RichTextKit = createKit("richTextKit", () => ({
  ...Core,

  // Nodes
  blockquote: Blockquote,
  bulletList: BulletList,
  emoji: EmojiNode,
  hardBreak: HardBreak,
  heading: Heading.configure({ levels: [2] }),
  listItem: ListItem,
  orderedList: OrderedList,

  // Marks
  bold: Bold,
  italic: Italic,
  link: Link,
  strike: Strike,
  subscript: Subscript,
  superscript: Superscript,
  underline: Underline,

  // Functionality
  blockTemplate: BlockTemplate,
  id: Id,
  invisibleCharacters: InvisibleCharactersExtension,
  removeStyle: RemoveStyleExtension,
  specialCharacters: SpecialCharactersExtension,
  toggleCase: ToggleCaseExtension,
  typographicRules: TypographicRulesExtension,
}));

export type RichTextKitOptions = KitOptions<typeof RichTextKit>;
