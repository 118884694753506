import {
  Extensions,
  JSONContent,
  generateJSON,
  generateText,
} from "@tiptap/core";

import { CustomFieldValueRichTextNodeFragmentFragment } from "@/gql-types";

type RichTextNode = Partial<CustomFieldValueRichTextNodeFragmentFragment>;

export const convertRichTextNodeToContent = (
  nodes: RichTextNode[],
  extensions: Extensions,
): JSONContent => {
  const result: JSONContent[] = [];
  let currentList: JSONContent | null = null;

  nodes.forEach((node) => {
    const { content } = generateJSON(node.text ?? "", extensions);

    // RichTextNode type to TipTap editor nodes type mapping
    const type = (() => {
      switch (node.metadata?.type) {
        case "paragraph":
        case "blockquote":
          return node.metadata.type;
        case "header_two":
          return "heading";
        case "ordered_list_item":
          return "orderedList";
        case "unordered_list_item":
          return "bulletList";
        default:
          return "paragraph";
      }
    })();

    if (type.includes("List")) {
      // Each list item is wrapped in a list node, so we need to group them
      // together to form a cohesive list that the TipTap editor can properly interpret.
      if (currentList && currentList.type === type) {
        currentList.content!.push({
          type: "listItem",
          attrs: { id: node.id ?? null },
          content: [{ type: "paragraph", content: content[0].content }],
        });
      } else {
        currentList = {
          type,
          content: [
            {
              type: "listItem",
              attrs: { id: node.id ?? null },
              content: [{ type: "paragraph", content: content[0].content }],
            },
          ],
        };
        result.push(currentList);
      }
    } else {
      if (currentList) currentList = null; // Reset current list

      const attrs = {
        styleName: node.metadata?.styleName ?? null,
        id: node.id ?? null,
      };
      switch (type) {
        case "heading":
          result.push({
            type,
            content: content[0].content ?? [],
            attrs: { level: 2, ...attrs },
          });
          break;
        case "paragraph":
          result.push({
            type,
            content: content[0].content ?? [],
            attrs,
          });
          break;
        case "blockquote":
        case "orderedList":
        case "bulletList":
          result.push({
            type,
            content,
            attrs,
          });
          break;
        default:
          break;
      }
    }
  });

  return { type: "doc", content: result };
};

const tagMap = {
  bold: "strong",
  italic: "em",
  underline: "u",
  strike: "s",
  subscript: "sub",
  superscript: "sup",
  link: "a",
};

export const convertContentToRichTextNode = (
  content: JSONContent,
  extensions: Extensions,
): RichTextNode[] => {
  return (
    content.content?.flatMap((node): RichTextNode[] => {
      const text = generateText(node, extensions, {
        textSerializers: {
          text: ({ node }) => {
            let formattedText = node.text || "";
            node.marks?.forEach((mark) => {
              const tag = tagMap[mark.type.name as keyof typeof tagMap];
              if (tag) {
                const attrs = Object.entries(mark.attrs).map(
                  ([key, value]) => `${key}="${value}"`,
                );
                formattedText = `<${[tag, ...attrs].join(" ")}>${formattedText}</${tag}>`;
              }
            });
            return formattedText;
          },
          hardBreak: () => "<br />",
        },
        blockSeparator: "",
      });

      // TipTap editor nodes type to RichTextNode type mapping
      const type = (() => {
        switch (node.type) {
          case "blockquote":
          case "paragraph":
            return node.type;
          case "heading":
            return "header_two";
          case "orderedList":
            return "ordered_list_item";
          case "bulletList":
            return "unordered_list_item";
          default:
            return "paragraph";
        }
      })();

      if (type.includes("list_item")) {
        // Unwrap list item
        return (
          node.content?.map((listItemNode) => ({
            ...(listItemNode.attrs?.id ? { id: listItemNode.attrs.id } : {}),
            type: "text",
            text: generateText(listItemNode.content![0]!, extensions),
            metadata: { type },
          })) ?? []
        );
      }

      return [
        {
          ...(node.attrs?.id ? { id: node.attrs.id } : {}),
          type: "text",
          text,
          metadata: {
            type,
            ...(node.attrs?.styleName
              ? { styleName: node.attrs?.styleName }
              : {}),
          },
        },
      ];
    }) ?? []
  );
};
