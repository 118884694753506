import { tz } from "@date-fns/tz";
import {
  formatISO,
  intervalToDuration,
  isToday,
  format as rawFormat,
  set,
} from "date-fns";
import { fr } from "date-fns/locale";

export type DateLike = Date | number | string;

export const formatDuration = (seconds?: number | null) => {
  if (seconds == null) return "–";

  const duration = intervalToDuration({ start: 0, end: seconds * 1e3 });

  if (duration.months) {
    return `${duration.months}mois et ${duration.days ?? 0}j`;
  }
  if (duration.days) {
    return `${duration.days}j et ${duration.hours ?? 0}h`;
  }
  if (duration.hours) {
    return `${duration.hours}h et ${duration.minutes ?? 0}min`;
  }
  if (duration.minutes) {
    return `${duration.minutes}min et ${duration.seconds ?? 0}sec`;
  }
  return `${duration.seconds ?? 0}sec`;
};

export const formatPublicationDate = (date: DateLike): string => {
  return isToday(date)
    ? `à ${rawFormat(date, "H:mm")}`
    : `le ${rawFormat(date, "dd/MM/yyyy")}`;
};

export const formatISOinUTC = (date: DateLike): string => {
  return (
    formatISO(date, { in: tz("UTC") })
      // Manually add milliseconds
      .replace(/Z$/, rawFormat(date, `'.'SSS'Z'`))
  );
};

export const formatForParis = (date: DateLike, pattern: string): string => {
  return rawFormat(date, pattern, { locale: fr, in: tz("Europe/Paris") });
};

export type DateTimePart = { date: string; time: string };

export const dateToParts = (value?: DateLike | null): DateTimePart | null => {
  if (!value) return null;
  const date = formatISO(value, { in: tz("UTC") });
  return {
    date: rawFormat(date, "yyyy-MM-dd", { in: tz("Europe/Paris") }),
    time: rawFormat(date, "HH:mm:ss", { in: tz("Europe/Paris") }),
  };
};

export const partsToDate = (dateParts?: DateTimePart | null): Date | null => {
  if (!dateParts) return null;
  if (!dateParts.time || !dateParts.date) return null;
  const [hours = 0, minutes = 0] = dateParts.time.split(":").map(Number);
  return set(dateParts.date, { hours, minutes }, { in: tz("Europe/Paris") });
};
