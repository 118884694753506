import React, { forwardRef } from "react";

import { cn } from "../utils/classNames";

type RadioScales = "sm" | "lg" | "md";

type RadioInputProps = {
  "data-scale"?: RadioScales;
  disabled?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

type RadioProps = {
  "data-scale"?: RadioScales;
  disabled?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

type RadioLabelProps = {
  "data-scale"?: RadioScales;
  "aria-invalid"?: boolean;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

export const Radio = forwardRef<HTMLInputElement, RadioInputProps>(
  (props, ref) => {
    return (
      <input
        ref={ref}
        type="radio"
        className={cn(
          "flex h-4 w-4 appearance-none items-center justify-around rounded-full border p-0 transition-all",
          "hover:border-primary-border",
          "focus:border-primary-border",
          "disabled:focus:border-grey-border",
          "disabled:hover:border-grey-border",
          "focus:ring-2 focus:ring-primary-bg",
          "disabled:opacity-40",
          "checked:before:bg-primary-bg-strong",
          "aria-[invalid=true]:border-danger-border-strong",
          "aria-[invalid=true]:focus:border-danger-border-strong",
          "aria-[invalid=true]:hover:border-danger-border-strong",
          "aria-[invalid=true]:focus:ring-2 aria-[invalid=true]:focus:ring-danger-bg",
          `before:block before:h-3 before:w-3 before:rounded-full before:bg-transparent before:transition-all before:content-[""]`,
          "group/label:data-[scale=lg]:w-5 group/label:data-[scale=lg]:h-5 group/label:data-[scale=lg]:before:w-4 group/label:data-[scale=lg]:before:h-4",
          "group/label:data-[scale=sm]:w-3 group/label:data-[scale=sm]:h-3 group/label:data-[scale=sm]:before:w-2 group/label:data-[scale=sm]:before:h-2",
        )}
        {...props}
      />
    );
  },
);

export const RadioLabel = forwardRef<HTMLLabelElement, RadioLabelProps>(
  (props, ref) => {
    const { className, ...rest } = props;
    return (
      <label
        ref={ref}
        className={cn(
          "group/label leading-1 flex items-center gap-2 transition-all",
          "aria-[disabled=true]:opacity-40",
          "data-[scale=lg]:text-lg",
          "data-[scale=sm]:text-sm",
          className,
        )}
        {...rest}
      />
    );
  },
);

export const RadioGroup = forwardRef<HTMLDivElement, RadioProps>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        role="radiogroup"
        className={cn(
          "group flex flex-wrap gap-4",
          "data-[scale=lg]:gap-3",
          "data-[scale=sm]:gap-1",
          "has-[:disabled]:opacity-40",
          'data-[orientation="vertical"]:flex-col',
          'data-[orientation="vertical"]:flex-wrap',
          'data-[orientation="vertical"]:gap-2',
        )}
        {...props}
      />
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Radio.displayName = "Radio";
  RadioGroup.displayName = "RadioGroup";
  RadioLabel.displayName = "RadioLabel";
}
